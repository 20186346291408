import { BOT_VARIABLE_VALIDATE_MESSAGE } from '@configs/bot_variable';
import { ERROR_TITLE_DEAULT_MESSAGE, ERROR_DESCRIPTION_DEAULT_MESSAGE } from '../errors';

export default {
  //general message
  'general.search.no.result.title': 'No results found',
  'general.search.no.result.description': 'Try changing the search keywords',
  'general.error.title': ERROR_TITLE_DEAULT_MESSAGE,
  'general.error.description': ERROR_DESCRIPTION_DEAULT_MESSAGE,
  'general.error.description.internet.problem':
    'Please check your internet connection, or try again later.',
  'general.ok.btn': 'OK',
  'general.try.again.btn': 'Try again',
  confirm: 'Confirm',
  cancel: 'Cancel',
  'general.create': 'Create',
  'general.search': 'Search',
  'export.btn': 'Export',
  //error page
  'error.page.back.to.home.btn': 'Back Home',
  'error.page.back.to.login.btn': 'Back to login',
  // login page
  'login.page.username.required': 'Please input your username!',
  'login.page.username.placeholder': 'Username',
  'login.page.password.required': 'Please input your password!',
  'login.page.password.placeholder': 'Password',
  'login.page.btn': 'Log in',
  // change password page
  'change.password.page.title': 'Setup your password',
  'change.password.page.enter.email': 'Please enter your email!',
  'change.password.page.password.label': 'Password',
  'change.password.page.password.help':
    'Requires a combination of alphanumeric characters, uppercase and lowercase letters, and special characters.',
  'change.password.page.password.placeholder': 'Password',
  'change.password.page.submit': 'Continue',
  // reset password page
  'reset.password.page.title': 'Reset password',
  'reset.password.page.enter.code': 'Please enter your code!',
  'reset.password.page.enter.code.label': 'Code',
  'reset.password.page.enter.password': 'Please enter your password!',
  'reset.password.page.password.label': 'Password',
  'reset.password.page.password.help':
    'Requires a combination of alphanumeric characters, uppercase and lowercase letters, and special characters.',
  'reset.password.page.password.placeholder': 'Password',
  'reset.password.page.submit': 'Reset',
  'reset.password.page.go.back': 'Go back',
  'reset.password.resend.label': 'Didn’t receive an email?',
  'reset.password.resend.button': 'Resend',
  'reset.password.resend.note':
    'Please check your spam folder before requesting the code \nagain.',
  // dashboard
  'dashboard.fetch.error.message': 'Something wrong',
  'dashboard.fetch.error.retry.button': 'Refresh',
  'dashboard.title': 'Overview',
  'dashboard.beta': 'Beta',
  'dashboard.dropdown.channel.placeholder': 'All Channel',
  'dashboard.dropdown.channel.no.result': 'No result',
  'dashboard.total.message.chart.title': 'Total Messages',
  'dashboard.dropdown.channel.selected.count.label': 'channels selected',
  'dashboard.total.message.chart.description': 'All messages',
  'dashboard.total.message.chart.action.list.view.timestamp': 'View timestamp',
  'dashboard.total.message.chart.action.list.download.csv': 'Download CSV',
  'dashboard.total.message.card.title': 'Total Messages',
  'dashboard.total.message.card.description': 'Number of all messages',
  'dashboard.total.message.number.extra': 'Message(s)',
  'dashboard.total.message.card.bot.response.title': 'Bot Response',
  'dashboard.total.message.card.bot.response.description': 'Number of bot response',
  'dashboard.dropdown.segment.no.result': 'No result',
  'dashboard.dropdown.segment.placeholder': 'All Segment',
  'dashboard.dropdown.segment.selected.count.label': 'segments selected',

  'dashboard.max.message.card.title': 'Max. Messages',
  'dashboard.max.message.card.description': 'Maximum messages per conversation',
  'dashboard.avg.message.card.title': 'Avg. Messages',
  'dashboard.avg.message.card.description': 'Average per conversation',

  'dashboard.top.user.card.title': 'Top User',
  'dashboard.top.user.card.description': 'Top 10 users',

  'dashboard.total.user.chart.title': 'Active Users',
  'dashboard.total.user.chart.description': 'Active users',
  'dashboard.total.user.card.title': 'Active Users',
  'dashboard.total.user.card.description': 'Number of unique users',
  'dashboard.total.user.number.extra': 'User(s)',
  'dashboard.new.user.card.new.user.acquire.response.title': 'New Users Acquire',
  'dashboard.new.user.card.new.user.acquire.description': 'First-time users',
  'dashboard.heatmap.chart.title': 'Heat Map : Messages',
  'dashboard.heatmap.chart.description': 'Average message per hour',

  // dashboard user journey
  'dashboard.user.journey.title': 'User journey ',
  'dashboard.user.journey.previous.label': 'Previous action',
  'dashboard.user.journey.current.label': 'Current action',
  'dashboard.user.journey.next.label': 'Next action',
  'dashboard.user.journey.empty.paragraph': `Click + to add Template, Richmenu or Mapper\n to preview the state of user journey`,
  'dashboard.user.journey.add.new.label': 'Add Template, Rich Menu or Mapper',
  'dashboard.user.journey.switch.show.title.label': 'Show Carousel Title',
  'dashboard.user.journey.pie.label': 'Current Stage',
  'dashboard.user.journey.pie.export.current.stage': 'Current Stage',
  'dashboard.user.journey.pie.export.current.all.template.richmenu':
    'All Template/Richmenu',
  // journey insight
  'journey.insight.title': 'Journey Insight',
  'journey.insight.conversation.outcome.label': 'Conversation Outcome',
  'journey.insight.conversation.outcome.completed.label': 'Completed',
  'journey.insight.conversation.outcome.incompleted.label': 'Incompleted',
  'journey.insight.conversation.outcome.dropoff.label': 'Drop-Off',
  'journey.insight.conversation.outcome.conversations.description': 'conversations',
  'journey.insight.conversation.outcome.of.total': 'of total',
  'journey.insight.conversation.outcome.top.twenty': 'Top 20 Outcomes',
  'journey.insight.conversation.outcome.top.twenty.last.response': 'Last Responses',
  'journey.insight.conversation.outcome.top.twenty.value': 'Value',
  'journey.insight.conversation.outcome.top.twenty.journey': ' Journey (%)',
  'journey.insight.conversation.outcome.top.twenty.action.all': 'All',
  'journey.insight.conversation.outcome.top.twenty.action.completed': 'Completed',
  'journey.insight.conversation.outcome.top.twenty.action.incompleted': 'Incompleted',
  'journey.insight.conversation.outcome.top.twenty.action.dropoff': 'Drop-off',
  'journey.insight.report.title': 'Export report',
  'journey.insight.report.type.title': 'Type of reports',
  'journey.insight.report.type.description':
    'The report can export up to 1,000,000 rows.',
  'journey.insight.report.type.conversation.outcome.report':
    'Conversation outcomes Report',
  'journey.insight.report.type.user.journey.report': 'User Journey Report',
  'sankey.wrap.view.full.screen': 'View Full Screen',
  'sankey.wrap.last.action': 'LAST ACTION',
  'sankey.wrap.modal.journey.flow': 'Journey Flow',
  // channel management
  'channel.management.title': 'Channels',
  'channel.management.description':
    'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
  'channel.management.network.tab.title': 'Network',
  'channel.management.network.tab.default.mode.label': 'Default mode',
  'channel.management.network.tab.default.mode.select.placeholder': 'Select option',
  'channel.management.network.tab.save.btn': 'Save change',
  'channel.management.network.tab.channel.list.title': 'Channels in network',
  'channel.management.network.tab.channel.list.show.more': 'Show more',
  'channel.management.channels.tab.title': 'Channels',
  'channel.management.channels.tab.all.channel.title': 'All channels',
  'channel.management.channels.tab.add.new.channel': 'Add new channel',
  'channel.management.channels.tab.add.new.channel.dropdown.title': 'CHOOSE YOUR CHANNEL',
  'channel.management.channels.tab.modal.confirm.button': 'Connect',
  'channel.management.channels.tab.modal.close.button': 'Close',
  'channel.management.channels.tab.modal.line.header': 'Add new line channel',
  'channel.management.channels.tab.modal.facebook.header': 'Add new facebook channel',
  'channel.management.channels.tab.modal.webchat.header': 'Add new webchat channel',
  'channel.management.channels.tab.modal.custom.header': 'Add new custom channel',
  'channel.management.channels.tab.fetch.fail.label': 'Failed to load channel',
  'channel.management.channels.tab.toggle.fail.label':
    'Failed to toggle activate channel',
  'channel.management.channels.tab.delete.modal.title': 'Delete channel',
  'channel.management.channels.tab.delete.modal.description':
    'Are you sure to delete this channel?',
  'channel.management.channels.tab.delete.fail.label': 'Delete channel failed',
  // channel management line
  'channel.management.modal.edit.title.line': 'Edit channel',
  'channel.management.modal.create.title.line': 'Add new LINE channel',
  'channel.management.modal.line.form.name': 'Channel name:',
  'channel.management.modal.line.form.name.placeholder': 'Create channel name',
  'channel.management.modal.line.form.name.tooltip':
    'Do not use special character, e.g., ! @ # ? ]',
  'channel.management.channels.tab.modal.line.form.name.validate':
    'Do not use special character',
  'channel.management.modal.line.form.id.title': 'Channel ID:',
  'channel.management.modal.line.form.id.description':
    'You can get channel ID from line developers',
  'channel.management.modal.line.form.id.check.link': 'Click to check',
  'channel.management.modal.line.form.token.title': 'Channel token:',
  'channel.management.modal.line.form.token.description':
    'You can get  channel token from line developers',
  'channel.management.modal.line.form.token.check.link': 'Click to check',
  'channel.management.modal.line.form.secret.title': 'Channel secret:',
  'channel.management.modal.line.form.secret.description':
    'You can get channel secret from line developers',
  'channel.management.modal.line.form.secret.check.link': 'Click to check',
  'channel.management.modal.line.form.activate.title': 'Activate channel',
  'channel.management.modal.line.form.name.validate': 'Do not use special character',
  'channel.management.channels.tab.modal.delete.confirm.button': 'Delete',
  'channel.management.channels.tab.modal.delete.cancel.button': 'Close',

  // channel management faceboook
  'channel.management.modal.create.title.facebook': 'Add new Facebook channel',
  'channel.management.modal.edit.title.facebook': 'Edit channel',
  'channel.management.modal.facebook.form.name': 'Channel name:',
  'channel.management.modal.facebook.form.name.placeholder': 'Select page',
  'channel.management.modal.facebook.form.page.id': 'Page ID:',
  'channel.management.modal.facebook.form.activate.title': 'Activate channel',
  'channel.management.modal.facebook.form.btn.login': 'Login with facebook',
  // channel management sdk
  'channel.management.modal.create.title.sdk': 'Add new Webchat channel',
  'channel.management.modal.edit.title.sdk': 'Edit channel',
  'channel.management.modal.sdk.form.name': 'Create channel name:',
  'channel.management.modal.sdk.form.admin.access.token': 'Admin Access Token:',
  'channel.management.modal.sdk.form.webhook.api.key': 'Webhook API Key',
  'channel.management.modal.sdk.form.activate.title': 'Activate channel',
  'channel.management.modal.sdk.form.name.validate': 'Do not use special character',
  'channel.management.modal.sdk.form.channel.id': 'Channel ID',
  'channel.management.modal.sdk.form.channel.token': 'Token',
  'channel.management.modal.sdk.form.channel.token.revoke': 'Revoke',
  'channel.management.modal.sdk.form.name.placeholder': 'Create channel name',
  'channel.management.modal.sdk.form.secure.label': 'Secure webchat',
  'channel.management.modal.sdk.form.secure.description':
    'Authenticate with your backend server to ensure secure access before initiating a conversation.',
  'channel.management.modal.sdk.form.add.more.secret.key': 'Add more secret key',
  // channel management custom
  'channel.management.modal.edit.title.custom': 'Edit channel',
  'channel.management.modal.create.title.custom': 'Add new Custom channel',
  'channel.management.modal.custom.form.name': 'Channel name:',
  'channel.management.modal.custom.form.name.validate': 'Do not use special character',
  'channel.management.modal.custom.form.webhook': 'Webhook URL:',
  'channel.management.modal.custom.form.webhook.white.space.validate':
    'whitespace is not allowed',
  'channel.management.modal.custom.form.webhook.invalid.url.format': 'invalid url format',
  'channel.management.modal.custom.form.channel.id': 'Channel ID:',
  'channel.management.modal.custom.form.channel.id.placeholder': 'Generate channel ID',
  'channel.management.modal.custom.form.api.key': 'API key:',
  'channel.management.modal.custom.form.api.key.placeholder': 'Generate API key',
  'channel.management.modal.custom.form.generate.api.key': 'Revoke',
  'channel.management.modal.sdk.form.channel.api.key': 'API key',
  'channel.management.modal.sdk.form.channel.api.key.revoke': 'Revoke',
  'channel.management.modal.sdk.form.period.time': 'Expiry time',
  'channel.management.modal.sdk.form.hours.unit': 'Hours',
  'channel.management.modal.sdk.form.min.unit': 'Minutes',
  // user management
  //user management -> team management pane
  'user.management.header.select.placeholder': 'Role',
  'user.management.header.title': 'Team Management',
  'user.management.add.new.member.btn': 'Add new member',
  'user.management.add.new.team.btn': 'Create new team',
  'user.management.member.list.empty.title': 'No member here',
  'user.management.member.list.empty.description': 'Please add new member to start...',
  'user.management.team.list.empty.title': 'No team here',
  'user.management.team.list.empty.description': 'Please add new team to start...',
  'user.management.modal.delete.member.cancel.button': 'Cancel',
  'user.management.modal.delete.member.confirm.button': 'Confirm',
  'user.management.modal.delete.member.title':
    'Are you sure you want to delete this {username}?',
  'user.management.modal.delete.team.cancel.button': 'Cancel',
  'user.management.modal.delete.team.confirm.button': 'Confirm',
  'user.management.modal.delete.team.title':
    'Are you sure you want to delete this {teamName}?',

  //user management -> member editor add new
  'user.management.member.editor.breadcrumb.page.before': 'Team Management',
  'user.management.member.editor.breadcrumb.page.current.new': 'Add new member',
  'user.management.member.editor.breadcrumb.page.current.edit': 'Edit member',
  'user.management.member.editor.breadcrumb.page.current.reset.password':
    'Reset password',
  'user.management.member.editor.title': 'Add new member',
  'user.management.member.editor.username.label': 'Create username',
  'user.management.member.editor.username.tooltip.1':
    'Can contain lowercase (a-z) numbers (0-9) and dot (.)',
  'user.management.member.editor.username.tooltip.2':
    'Cannot contain special character, e.g., _ ! @ # ? ]',
  'user.management.member.editor.username.tooltip.3': 'Cannot contain space bar',
  'user.management.member.editor.username.required': 'username is required',
  'user.management.member.editor.username.validate': 'invalid username format',
  'user.management.member.editor.email.validate': 'Invalid email address',
  'user.management.member.editor.email.required': 'email is required',
  'user.management.member.editor.phone.validate': 'Invalid phone number format',
  'user.management.member.editor.email.label': 'Email address',
  'user.management.member.editor.phone.label': 'Phone number (optional)',
  'user.management.member.editor.role.label': 'Role',
  'user.management.member.editor.role.required': 'role is required',
  'user.management.member.editor.password.label': 'Create password',
  'user.management.member.editor.confirm.password.label': 'Confirm password',
  'user.management.member.editor.confirm.password.tooltip.1': 'Password must have:',
  'user.management.member.editor.confirm.password.tooltip.2': 'At least 8 characters',
  'user.management.member.editor.confirm.password.tooltip.3':
    'At least one uppercase letter',
  'user.management.member.editor.confirm.password.tooltip.4':
    'At least one lowercase letter',
  'user.management.member.editor.confirm.password.tooltip.5': 'At least one number',
  'user.management.member.editor.confirm.password.tooltip.6':
    'At least one special character',
  'user.management.member.editor.username.placeholder': 'Ex. sampleusername',
  'user.management.member.editor.email.placeholder': 'Email address',
  'user.management.member.editor.phone.placeholder': '0XX-XXX-XXXX',
  'user.management.member.editor.role.placeholder': 'Select role',
  'user.management.member.editor.password.placeholder': 'Enter password',
  'user.management.member.editor.password.required': 'password is required',
  'user.management.member.editor.password.min': 'minimum of 8 characters',
  'user.management.member.editor.password.lowercase': 'contains a lowercase letter',
  'user.management.member.editor.password.uppercase': 'contains an uppercase letter',
  'user.management.member.editor.password.numeric': 'contains a number (0-9)',
  'user.management.member.editor.confirm.password.placeholder': 'Enter password',
  'user.management.member.editor.confirm.password.required':
    'confirm password is required',
  'user.management.member.editor.confirm.password.no.match': 'Password doesn’t match',
  'user.management.member.editor.submit.btn': 'Create account',
  'user.management.member.editor.back.btn': 'Back',
  'user.management.member.editor.alert.add.new.success': 'Create account successfully',
  'user.management.member.editor.alert.edit.success': 'Save changes',

  //user management -> member editor edit
  'user.management.member.editor.edit.title': 'Edit member',
  'user.management.member.editor.username.edit.label': 'Username',
  'user.management.member.editor.email.edit.required': 'email is required',
  'user.management.member.editor.email.edit.validate': 'Invalid email address',
  'user.management.member.editor.email.edit.label': 'Email address',
  'user.management.member.editor.email.edit.placeholder': 'Email address',
  'user.management.member.editor.phone.edit.validate': 'Invalid phone number format',
  'user.management.member.editor.phone.edit.label': 'Phone number (optional)',
  'user.management.member.editor.phone.edit.placeholder': '0XX-XXX-XXXX',
  'user.management.member.editor.role.edit.label': 'Role',
  'user.management.member.editor.role.edit.placeholder': 'Select role',
  'user.management.member.editor.edit.back.btn': 'Back',
  'user.management.member.editor.edit.submit.btn': 'Save changes',
  'user.management.member.editor.edit.modal.role.change.cancel.btn': 'Cancel',
  'user.management.member.editor.edit.modal.role.change.confirm.btn': 'Confirm',
  'user.management.member.editor.edit.modal.role.change.title':
    'Are you sure you want to change roles? ',
  'user.management.member.editor.edit.modal.role.change.description':
    'It would have an impact on permission access.',
  //user management -> reset password editor
  'user.management.member.editor.reset.password.success.alert':
    'Reset password successfully',
  'user.management.member.editor.reset.password.title': 'Please change your password',
  'user.management.member.editor.reset.password.new.password.label': 'New password',
  'user.management.member.editor.reset.password.new.password.placeholder':
    '8+ characters, uppercase, lowercase, number',
  'user.management.member.editor.reset.password.new.password.required':
    'Please enter your password',
  'user.management.member.editor.reset.password.new.password.wrong.format':
    'Password is invalid',
  'user.management.member.editor.reset.password.required': 'password is required',
  'user.management.member.editor.reset.password.min': 'minimum of 8 characters',
  'user.management.member.editor.reset.password.lowercase': 'contains a lowercase letter',
  'user.management.member.editor.reset.password.uppercase':
    'contains an uppercase letter',
  'user.management.member.editor.reset.password.numeric': 'contains a number (0-9)',
  'user.management.member.editor.reset.password.confirm.new.password.label':
    'Confirm password',
  'user.management.member.editor.reset.password.confirm.new.password.required':
    'Please enter your password',
  'user.management.member.editor.reset.password.confirm.new.password.wrong.format':
    'Password is invalid',
  'user.management.member.editor.reset.password.confirm.new.password.no.match':
    'Password doesn’t match',
  'user.management.member.editor.reset.password.confirm.new.password.placeholder':
    '8+ characters, uppercase, lowercase, number',
  'user.management.member.editor.reset.password.submit.btn': 'Set new password',
  'user.management.member.editor.reset.password.back.btn': 'Back',
  //user management -> team editor add new
  'user.management.team.editor.breadcrumb.page.current.new': 'Create new team',
  'user.management.team.editor.title': 'Create new team',
  'user.management.team.editor.name.required': 'team name is required',
  'user.management.team.editor.name.label': 'Team name',
  'user.management.team.editor.name.placeholder': 'Your team name',
  'user.management.team.editor.department.label': 'Department (optional)',
  'user.management.team.editor.department.placeholder': 'Team department',
  'user.management.team.editor.set.default.label': 'Set as default team',
  'user.management.team.editor.set.default.label.description':
    'To set one team to allocate ticket',
  'user.management.team.editor.add.member.label': 'Add team member',
  'user.management.team.editor.add.member.description':
    'Select existing team members into your team, or ',
  'user.management.team.editor.add.member.link': 'Add new member',
  'user.management.team.editor.alert.add.new.team.success': 'Create team successfully',
  'user.management.team.editor.back.btn': 'Back',
  'user.management.team.editor.submit.btn': 'Create team',
  //user management -> team editor edit
  'user.management.team.editor.breadcrumb.page.current.edit': 'Edit team',
  'user.management.team.editor.edit.title': 'Edit team',
  'user.management.team.editor.edit.name.label': 'Team name',
  'user.management.team.editor.edit.name.required': 'Team name is required',
  'user.management.team.editor.edit.name.placeholder': 'Your team name',
  'user.management.team.editor.edit.department.label': 'Department (optional)',
  'user.management.team.editor.edit.department.placeholder': 'Team department',
  'user.management.team.editor.edit.set.default.label': 'Set as default team',
  'user.management.team.editor.edit.set.default.label.description':
    'To set one team to allocate ticket',
  'user.management.team.editor.edit.add.member.label': 'Add team member',
  'user.management.team.editor.edit.add.member.description':
    'Select existing team members into your team, or ',
  'user.management.team.editor.edit.add.member.add.member.link': 'Add new member',
  'user.management.team.editor.edit.back.btn': 'Back ',
  'user.management.team.editor.edit.submit.btn': 'Save changes',
  'user.management.team.editor.edit.alert.update.team.success':
    'Update team successfully',

  'cim.product.page.description':
    'Discover the power of our all-in-one solution. With cutting-edge technology and powerful features, \nour product streamlines your operations, maximizes your potential, and delivers a truly remarkable user experience',
  'cim.product.page.no.product.access.title': 'You don’t have product access',
  'cim.product.page.no.product.access.description':
    'You don’t have product access please contact your global admin',
  'cim.product.page.chatbot.label': 'Chatbot',
  'cim.product.page.chatbot.description':
    'Create a chatbot that engages and delights your customers with the latest AI and NLP technologies',
  'cim.product.page.livechat.label': 'Livechat',
  'cim.product.page.livechat.description':
    'Transform your customer service with our real-time Livechat, designed to provide quick and accurate solutions to your users.',
  'cim.product.page.campaign.label': 'Campaign',
  'cim.product.page.campaign.description':
    'Reach a wide audience instantly with our powerful automation and scheduling features, designed to elevate your communication game',
  'cim.product.page.report.label': 'Report and dashboard',
  'cim.product.page.report.description': 'Lorem',

  // cim user list
  'cim.member.list.page.title': 'Users',
  'cim.member.list.page.description': 'Manage your users',
  'cim.member.list.page.table.list.title': 'All users',
  'cim.member.list.page.form.filter.search.btn': 'Search',
  'cim.member.list.page.form.filter.search.placeholder': 'Search',
  'cim.member.list.page.form.filter.role.placeholder': 'Role',
  'cim.member.list.page.modal.delete.label': 'Are you sure you want to delete',
  'cim.member.list.page.modal.delete.description': '"{name}" will be deleted',
  'cim.member.list.page.modal.confirm.delete.btn': 'Delete',
  'cim.member.list.page.modal.cancel.delete.btn': 'Cancel',
  'cim.member.list.page.modal.delete.failed.title': 'Delete failed',
  'cim.member.list.page.modal.delete.failed.description': 'Please try again',

  // cim user editor
  'cim.member.editor.page.breadcrumb.members': 'Members',
  'cim.member.editor.page.breadcrumb.view.profile': 'View profile',
  'cim.member.editor.page.update.success': 'Information updated successfuly',
  'cim.member.editor.page.delete.success': 'Deleted member successfuly',
  'cim.member.editor.page.modal.confirm.delete.btn': 'Delete',
  'cim.member.editor.page.modal.cancel.delete.btn': 'Cancel',
  'cim.member.editor.page.modal.delete.label': 'Are you sure you want to delete',
  'cim.member.editor.page.modal.delete.description': '"{name}" will be deleted',
  'cim.member.detail.page.update.btn': 'Update',
  'cim.member.detail.page.edit.btn': 'Edit',
  'cim.member.detail.page.cancel.btn': 'Cancel',
  'cim.member.detail.page.remove.btn': 'Remove member',
  'cim.member.detail.page.role.product.access.title': 'Roles and products access/role',
  'cim.member.detail.page.product.access.label': 'Product access/role',
  'cim.member.detail.page.detail.about.label': 'About',
  'cim.member.detail.page.detail.email.label': 'Email',
  'cim.member.detail.page.detail.telephone.label': 'Telephone',
  'cim.member.detail.page.detail.joinDate.label': 'Joined date',
  'cim.member.detail.page.detail.last.login.label': 'Last login',
  'cim.member.editor.modal.failed.ok.btn': 'OK',
  'cim.member.detail.page.confirm.change.role.title': 'Change role to admin',
  'cim.member.detail.page.confirm.change.role.description': `This person will be removed from their \n team, and their tickets will be
  reassigned.\n Would you like to change positions?`,
  'cim.member.detail.page.re.activate.btn': 'Reactivate account',
  'cim.member.detail.page.confirm.re.activate.description':
    'Are you sure you want to Reactivate {name} account',
  'cim.member.detail.page.re.activate.success': 'Reactivate account successfully',
  'cim.member.detail.page.re.activate.failed': 'Reactivate account failed',
  'cim.member.detail.page.de.activate.btn': 'Deactivate account',
  // user profile page
  'userProfile.page.edit.btn': 'Edit profile',
  'userProfile.page.update.btn': 'Update',
  'userProfile.page.cancel.btn': 'Cancel',
  'userProfile.page.detail.about.label': 'About',
  'userProfile.page.detail.email.label': 'Email',
  'userProfile.page.detail.joinDate.label': 'Joined date',
  'userProfile.page.detail.last.login.label': 'Last login',
  'userProfile.page.product.access.label': 'Product access/role',
  'userProfile.page.detail.livechat.label': 'Livechat',
  'userProfile.page.detail.chatbot.label': 'Chatbot',
  'userProfile.page.detail.campaign.label': 'Campaign',
  'userProfile.page.detail.genai.label': 'GenAI',
  'userProfile.page.detail.insight.label': 'Insight',
  'userProfile.page.setting.label': 'Setting',
  'userProfile.page.setting.profileName': 'Profile name',
  'userProfile.page.setting.phoneNumber': 'Phone number',
  'userProfile.page.reset.password.btn': 'Reset password',
  'userProfile.page.update.success': 'Information updated successfuly',
  'userProfile.page.phone.helper.text':
    'Please follow this format (+country code) and your phone number',
  // template list
  'template.list.title': 'Template',
  'template.list.description': 'Manage your template',
  'template.list.tag.management.btn': 'Tag management',
  'template.list.template.editor.new.btn': 'New template',
  'template.list.all.label': 'All templates',
  'template.list.search.btn': 'Search',
  'template.list.table.label': 'All templates',
  'template.list.delete.modal.confirm.btn': 'Confirm',
  'template.list.delete.modal.cancel.btn': 'Cancel',
  'template.list.delete.modal.title': 'Are you sure you want to delete "{templateName}"?',
  'template.list.empty.title': 'Create template',
  'template.list.empty.description': 'Let’s create some template',
  'campaign.list.create.btn': 'New Campaign',
  'campaign.list.table.modal.delete.label': 'Are you sure you want to delete ',
  'campaign.list.table.modal.delete.description': '{campaignName} campaign?',
  'campaign.list.table.modal.confirm.delete.btn': 'Delete',
  'campaign.list.table.modal.confirm.cancel.btn': 'Cancel',
  'campaign.list.table.modal.delete.success.title': 'Campaign deleted',
  'campaign.list.table.modal.delete.failed.title':
    'Delete campaign failed please try again later',
  // campaign editor
  'campaign.editor.new.title': 'New campaign',
  'campaign.editor.edit.title': 'Edit campaign',
  'campaign.editor.description': 'Complete all sections for create your campaign',
  'campaign.editor.cancel.btn': 'Cancel',
  'campaign.editor.draft.btn': 'Save draft',
  'campaign.editor.schedule.btn': 'Schedule Campaign',
  'campaign.editor.limit.label': 'You can put only 60 campaings/hour',
  'campaign.editor.confirm.schedule.btn': 'Schedule Campaign',
  'campaign.editor.cancel.schedule.btn': 'Stay',
  'campaign.editor.schedule.modal.title': 'Schedule Campaign?',
  'campaign.editor.schedule.modal.now.description':
    'will be sent to \nAudiences after 5 mins delay',
  'campaign.editor.schedule.modal.not.now.description': 'will be sent to \nAudiences at',
  // campaign editor name and channel section
  'campaign.editor.name.channel.label': 'Campaign name & channel',
  'campaign.editor.name.label': 'Campaign name',
  'campaign.editor.name.placeholder': 'Enter campaign name',
  'campaign.editor.channel.label': 'Channel',
  'campaign.editor.channel.placeholder': 'Select channel',
  'campaign.editor.channel.no.result': 'No result',
  // campaign editor audience section
  'campaign.editor.audience.title': 'Audience',
  'campaign.editor.audience.target.label': 'Target',
  'campaign.editor.audience.everyone.radio.label': 'Everyone',
  'campaign.editor.audience.segment.radio.label': 'Users in Segment',
  'campaign.editor.audience.manual.radio.label': 'Manual add',
  'campaign.editor.audience.all': "Everyone in your channel's audience",
  'campaign.editor.audience.segment.label': 'Users in Segment',
  'campaign.editor.audience.segment.placeholder': 'Select segment',
  'campaign.editor.audience.segment.new.segment.label': 'New segment?',
  'campaign.editor.audience.segment.new.segment.upload.label': 'upload',
  'campaign.editor.audience.segment.new.segment.create.description':
    'for create new segment\n and please make sure your file follow this',
  'campaign.editor.audience.segment.new.template.file.label': 'template',
  'campaign.editor.audience.segment.new.segment.modal.title': 'Create new segment',
  'campaign.editor.audience.segment.new.segment.modal.placeholder': 'Enter segment name',
  'campaign.editor.segment.count.title': 'Total users added',
  'campaign.editor.segment.count.check': 'Check',
  // campaign editor audience manual add
  'campaign.editor.audience.manual.add.label': 'User in audience',
  'campaign.editor.audience.manual.add.placeholder': 'Search user',
  'campaign.editor.audience.manual.add.search.text':
    'You can search user in audience from ID or name',
  // campaign editor schedule section
  'campaign.editor.schedule.title': 'Schedule',
  'campaign.editor.schedule.label': 'Broadcast time',
  'campaign.editor.schedule.now.radio.label': 'Now',
  'campaign.editor.schedule.now.description.radio.label':
    '(will have 5 mins delay when campaign scheduled)',
  'campaign.editor.schedule.custom.date.time.utc.label': 'UTC +7:00',
  // campaign editor message section
  'campaign.editor.message.title': 'Message',
  'campaign.editor.message.label': 'Broadcast message',
  'campaign.editor.preview.label': 'Preview message',
  'campaign.editor.message.personalize.danger.pre': 'The use of a',
  'campaign.editor.message.personalize.danger.mid': 'personalized',
  'campaign.editor.message.personalize.danger.post':
    'template is not allowed for broadcasting to all users in the channel.',
  'campaign.editor.message.personalize.warning.pre': 'This is a ',
  'campaign.editor.message.personalize.warning.mid': 'personalized',
  'campaign.editor.message.personalize.warning.post':
    'template which should take longer time to send the campaign.',
  // campaign status
  'campaign.status.edit.btn': 'Edit',
  'campaign.status.page.title': 'Campaign details',
  'campaign.status.cancel.btn': 'Cancel campaign',
  'campaign.status.duplicate.btn': 'Duplicate',
  'campaign.status.delete.btn': 'Delete',
  'campaign.status.toast.title': 'Campaign status : ',
  'campaign.status.toast.schedule.txt': 'Schedule',
  'campaign.status.channel.and.name.label': 'Channel & campaign name',
  'campaign.status.channel.label': 'Channel',
  'campaign.status.campaign.name.label': 'Campaign name',
  'campaign.status.schedule.label': 'Schedule',
  'campaign.status.broadcast.time.label': 'Broadcast time',
  'campaign.status.audiences.title': 'Audiences',
  'campaign.status.audiences.label': 'Audiences',
  'campaign.status.segment.label': 'Segment',
  'campaign.status.total.target.label': 'Total target',
  'campaign.status.message.label': 'Message',
  'campaign.status.message.personalize.warning.1': 'This is a',
  'campaign.status.message.personalize.warning.2': 'personalized',
  'campaign.status.message.personalize.warning.3':
    'template which should take longer time to send the campaign.',
  'campaign.status.preview.message.label': 'Preview message',
  'campaign.status.broadcast.message.label': 'Broadcast message',
  'campaign.status.toast.executing.txt': 'Executing',
  'campaign.status.toast.executed.txt': 'Executed',
  'campaign.status.toast.failed.txt': 'Failed',
  'campaign.status.toast.draft.txt': 'Draft',
  'campaign.status.toast.executed.receive.label': 'Received : ',
  'campaign.status.toast.executed.not.receive.label': 'Not received : ',
  'campaign.status.confirm.cancel.schedule.btn': 'Cancel and save draft',
  'campaign.status.reject.confirm.schedule.btn': 'Keep Schedule',
  'campaign.status.cancel.schedule.title': 'Are you sure you want to cancel the',
  'campaign.status.cancel.schedule.desciption': 'campaign {campaignName} ?',
  // segment
  'segment.list.title': 'Segment',
  'segment.search.btn': 'Search',
  'segment.channel.dropdown.no.selected.text': 'All Channel',
  'segment.channel.dropdown.selected.multiple.text': 'channel selected',
  'segment.list.search.placeholder': 'Search by segment name',
  // template editor
  'template.editor.platform.support': 'Platform support',
  'template.editor.item.name.placeholder': 'Untitled template',
  'template.editor.item.total.message': 'Total messages',
  'template.editor.item.message.type.title': 'Message types',
  'template.editor.item.cancel.btn': 'Cancel',
  'template.editor.item.create.btn': 'Create',
  'template.editor.item.update.btn': 'Update',

  'template.editor.platform.line': 'LINE',
  'template.editor.platform.facebook': 'Facebook',
  'template.editor.platform.webchat': 'Amity webchat',
  'template.editor.platform.multi': 'Multi platform',

  'template.editor.empty.state.header': 'Add some message type',
  'template.editor.empty.state.description':
    'Click any message from left side to create your template.',
  'template.editor.change.alert.title': 'Change channel',
  'template.editor.change.alert.description':
    'If you want to change channel, the template. will clear your content in previous channel. Do you want to continue?',
  'template.editor.remove.message.modal.title': 'Delete message?',
  'template.editor.modal.alert.ok.btn': 'OK',
  'template.editor.remove.message.modal.cancel.btn': 'Cancel',
  'template.editor.remove.message.modal.confirm.btn': 'Delete',
  'template.editor.modal.alert.title': 'Oops! something missing',
  'template.editor.modal.alert.description':
    'Please make sure you finish your edit in template. name or message content',
  // template editor -> message type
  // carousel
  'template.editor.carousel.item.alert.replace.word.title': 'Character Limit',
  'template.editor.carousel.item.alert.replace.word.description':
    'Character Limit Due to the limitations of the LINE platform, adding an image or title will reduce the desciption limit to 60 characters. \n\n Keep the change, or remove your text?',
  'template.editor.carousel.delete.modal.confirm': 'Remove',
  'template.editor.carousel.delete.modal.cancel': 'Keep',
  // entity
  'entity.content.title': 'Entity',
  'entity.content.description': 'Manage your entities',
  'entity.content.export.btn': 'Export',
  'entity.content.import.btn': 'Import',
  'entity.content.search.btn': 'Search',
  'entity.content.empty.list.title': 'Create entity',
  'entity.content.empty.list.description': 'Let’s create entity to get started',
  //entity editor
  'entity.editor.edit.title': 'Edit entity',
  'entity.editor.create.title': 'Create entity',
  'entity.editor.cancel.btn': 'Cancel',
  'entity.editor.update.btn': 'Update',
  'entity.editor.create.btn': 'Create',
  'entity.editor.title': 'Entity name',
  'entity.editor.name.placeholder': 'Enter entity name',
  'entity.editor.name.helper': '(2-100 characters and _, -)',
  'entity.editor.attribute.label': 'Attributes',
  'entity.editor.explicit.hint.label': 'Auto Detect: ',
  'entity.editor.explicit.hint.description':
    'When enabled the entity will be analyzed on every message. This option is not applicable for Location and Text entity type. When disabled the entity will only be analyzed when the bot is asking for the entity',
  'entity.editor.explicit.checkbox.label': 'Auto Detect',
  'entity.editor.format.label': 'Format',
  'entity.editor.format.placeholder': 'Select option',
  'entity.editor.alert.name.dup.title': 'Do not use the same option name',
  'entity.editor.alert.name.dup.description': 'Please change your option name again',
  'entity.editor.alert.ok.btn': 'OK',
  'entity.editor.delete.modal.confirm.btn': 'Delete',
  'entity.editor.delete.modal.cancel.btn': 'Cancel',
  'entity.editor.delete.modal.label': 'Delete entity',
  'entity.editor.delete.modal.description':
    'Are you sure you want to delete this entity?',
  'entity.editor.create.success.message': 'Create entity successfully',
  'entity.editor.update.success.message': 'Update entity successfully',
  'entity.editor.delete.success.message': 'Delete entity successfully',
  'entity.format.location.syn.add.placeholder': 'Add synonyms',
  //format option
  'entity.format.option.name.label': 'Options',
  'entity.format.option.name.placeholder': 'Name',
  'entity.format.option.syn.placeholder': 'Add new synonyms',
  'entity.format.option.item.name.placeholder': 'Name',
  'entity.format.option.item.syn.placeholder': 'Add new synonyms',
  'entity.format.option.syn.helper':
    '(2-100 characters, add new with comma (,), Max 20 synonyms)',
  'entity.format.option.alert.name.title': 'Do not use the same option name',
  'entity.format.option.alert.name.description': 'Please change your option name again',
  'entity.format.option.alert.ok.btn': 'OK',
  //format location
  'entity.format.location.syn.helper':
    '(2-100 characters, add new with comma (,), Max 20 synonyms)',
  //intent
  'intent.content.title': 'Intent',
  'intent.content.description': 'Manage your intent',
  'intent.content.export.btn': 'Export',
  'intent.content.import.btn': 'Import intent',
  'intent.content.train.btn': 'Train',
  'intent.content.trained.btn': 'Trained',
  'intent.content.traininig.btn': 'Training...',
  'intent.content.vector.label': 'Vector Classifier',
  'intent.content.vector.last.train.time.label': 'Last train :',
  'intent.content.vector.last.train.by.label': 'Train by :',
  'intent.content.search.placeholder': 'Search intent name',
  'intent.content.search.btn': 'Search',
  'intent.content.empty.list.title': 'Create intent',
  'intent.content.empty.list.description': 'Let’s create intent to get started',
  'intent.content.text.label': 'Text Classifier',
  'intent.content.text.last.train.time.label': 'Last train :',
  'intent.content.text.last.train.by.label': 'Train by :',
  'intent.content.custom.label': 'Custom Classifier',
  // intent list
  'intent.list.column.intent.name': 'INTENTS NAMES',
  'intent.list.column.last.modify.name': 'LAST MODIFY NAME',
  'intent.list.column.last.modify.date': 'LAST MODIFY DATE',
  // intent editor
  'intent.editor.cancel.btn': 'Cancel',
  'intent.editor.update.btn': 'Update',
  'intent.editor.create.btn': 'Create',
  'intent.editor.edit.title': 'Edit intent',
  'intent.editor.title': 'Intent name',
  'intent.editor.name.placeholder': 'Enter intent name',
  'intent.editor.name.helper': '(2-100 characters and _, -)',
  'intent.editor.sample.title': 'Samples',
  'intent.editor.sample.placeholder': 'Add new samples',
  'intent.editor.sample.helper':
    '({min_sample_char}-{max_sample_char} characters, * Min 2 and max 100 samples)',
  'intent.editor.create.success.message': 'Create intent successfully',
  'intent.editor.update.success.message': 'Update intent successfully',
  'intent.editor.delete.success.message': 'Delete intent successfully',
  'intent.editor.delete.modal.label': 'Delete intent',
  'intent.editor.delete.modal.description':
    'Are you sure you want to delete this variable?',
  'intent.editor.delete.modal.confirm.btn': 'Delete',
  'intent.editor.delete.modal.cancel.btn': 'Cancel',
  'intent.editor.alert.name.dup.title': 'This intent name already exists',
  'intent.editor.alert.name.dup.description': 'Please change your intent name again.',
  'intent.editor.alert.sample.dup.title': 'Do not use the same samples',
  'intent.editor.alert.sample.dup.description': 'Please change your sample again.',
  'intent.editor.alert.leave.edit.title':
    'Are you sure you want to leave this page? This page that you made will not be saved.',
  'intent.editor.alert.leave.edit.leave.page.button': 'Leave page',
  'intent.editor.alert.leave.edit.stay.button': 'Stay',
  'intent.editor.alert.ok.btn': 'OK',

  // intent import modal
  'intent.import.modal.title': 'Import CSV',
  'intent.import.modal.description': 'Select a CSV file to upload',
  'intent.import.modal.maximum.size': 'Maximum size is 5MB',
  'intent.import.modal.download.template': 'Download CSV Template',
  'intent.import.modal.choose.file.button': 'Choose File',
  'intent.import.modal.validating.message': 'Validating File...',
  'intent.import.upload.success.message': 'Update intent successfully',
  'intent.import.upload.failed.title.file.too.large': 'The selected file is too large',
  'intent.import.upload.failed.description.message': 'Please check your CSV file again.',
  'intent.import.upload.success.with.condition.message':
    'Update intent successfully with the condition',
  'intent.import.upload.success.with.condition.btn': 'See detail',
  // intent write error detail modal
  'intent.write.error.modal.title': 'Found duplicated',
  'intent.write.error.modal.description':
    '{count, plural, =0 {no intent} one {# intent} other{# intents}} that cannot be updated, please check your csv file.',
  'intent.write.error.modal.close.btn': 'Close',
  // mapper
  'mapper.content.title': 'Mapper',
  'mapper.content.create.btn': 'Create mapper',
  'mapper.search.placeholder': 'Search',
  'mapper.search.btn': 'Search',
  'mapper.all.label': 'All mappers',
  'mapper.content.editor.empty': 'Click your mapper to preview \nor add new mapper',
  'mapper.content.list.empty.title': 'Create mapper',
  'mapper.content.list.empty.description': 'Let’s create mapper to get started',
  'mapper.editor.create.mode.title': 'Create mapper',
  'mapper.editor.create.mode.create.btn': 'Create',
  'mapper.editor.update.mode.title': 'Edit mapper',
  'mapper.editor.update.mode.update.btn': 'Update',
  'mapper.editor.update.mode.cancel.btn': 'Cancel',
  'mapper.editor.intent.label': 'Intent',
  'mapper.editor.intent.placeholder': 'Search intent',
  'mapper.editor.entities.label': 'Entities',
  'mapper.editor.entities.placeholder': 'Search and select entities',
  'mapper.editor.entities.full': 'You selected maximum entities',
  'mapper.editor.action.type.label': 'Action',
  'mapper.editor.action.template.selected.label': 'Template',
  'mapper.editor.action.conversation.flow.selected.label': 'Conversation flow',
  'mapper.editor.action.conversation.flow.placeholder': 'Select option',
  'mapper.editor.action.conversation.flow.action.selected.label': 'Start action',
  'mapper.editor.action.conversation.flow.action.placeholder': 'Select option',
  'mapper.editor.template.placeholder': 'Search template',
  'mapper.editor.no.result': 'No result',
  'mapper.content.search.not.found.title': 'No results found',
  'mapper.content.search.not.found.description': 'Try changing the search keywords',
  'mapper.editor.modal.confirm.delete.btn': 'Delete',
  'mapper.editor.modal.cancel.delete.btn': 'Cancel',
  'mapper.editor.modal.delete.label': 'Delete mapper',
  'mapper.editor.modal.delete.description':
    'Are you sure you want to delete this mapper?',
  // campaign
  'campaign.list.title': 'Broadcast',
  'campaign.list.tab.scheduled': 'Scheduled',
  'campaign.list.tab.executing': 'Executing',
  'campaign.list.tab.executed': 'Executed',
  'campaign.list.tab.failed': 'Failed',
  'campaign.list.tab.draft': 'Draft',
  'campaign.list.table.header': 'All campaigns',
  // campaign chatads list
  'campaign.chatads.list.title': 'ChatAds',
  'campaign.chatads.list.template.title': 'Template',
  'campaign.chatads.list.template.description':
    'To help you create interactive ChatAds that meet the following usability standards, ChatAds Template Assistant provides suggested message types. Contents can be easily replaced and customised per requirement.',
  'campaign.chatads.list.template.ecommerce.title': 'E-commerce',
  'campaign.chatads.list.template.ecommerce.description':
    'Initiate your E-commerce ChatAds journey Carousel message helps convert traffic into your e-commerce platforms.',
  'campaign.chatads.list.template.faq.title': 'FAQ',
  'campaign.chatads.list.template.faq.description':
    'Initiate your FAQ ChatAds journey. Button message provides quick access to information.',
  'campaign.chatads.list.template.promotion.title': 'Promotion',
  'campaign.chatads.list.template.promotion.description':
    'Initiate your Promotion ChatAds journey. Imagemap message is captivating and makes the content more interesting to click.',
  'campaign.chatads.list.table.header.title': 'All ChatAds',
  'campaign.chatads.list.table.header.search.placeholder': 'Search ChatAds',
  'campaign.chatads.list.search.btn': 'Search',
  'campaign.chatads.list.empty.title': 'No chatads list now',
  'campaign.chatads.list.empty.description': 'New chatads list will show up here',
  'campaign.chatads.list.table.delete.modal.title':
    'Are you sure you want to delete "{name}"?',
  'campaign.chatads.list.table.delete.modal.confirm.btn': 'Delete',
  'campaign.chatads.list.table.delete.modal.cancel.btn': 'Cancel',
  'campaign.chatads.list.alert.ok.btn': 'OK',
  // campaign chatads editor
  'campaign.chatads.editor.content.name.placeholder': 'Untitled ChatAds name',
  'campaign.chatads.editor.content.cancel.btn': 'Cancel',
  'campaign.chatads.editor.content.preview.btn': 'Review',
  'campaign.chatads.editor.content.save.template.btn': 'Save template',
  'campaign.chatads.editor.content.scenario.id': 'Scenario ID',
  'campaign.chatads.content.scenario.helper.text':
    'Please input scenario ID that provided by LINE Ads platform. For draft version can leave it blank, the system will automatically generate the draft scenario ID.',
  'campaign.chatads.editor.content.message.label': 'Message',
  'campaign.chatads.editor.content.topic.name.placeholder': 'Topic name',
  'campaign.chatads.editor.content.create.btn': 'Create',
  'campaign.chatads.editor.content.save.btn': 'Save',
  'campaign.chatads.editor.content.keywords.label': 'Add triggered keywords',
  'campaign.chatads.editor.content.keywords.placeholder': 'keywords',
  'campaign.chatads.editor.content.keywords.add.btn': 'Add',
  'campaign.chatads.editor.content.keywords.helper.txt':
    'Triggered keywords can have maximum length 30 characters each and there can be less than or equal 50 keywords per a Bot message',
  'campaign.chatads.editor.content.total.message': 'Total messages',
  'campaign.chatads.editor.one.message.required': '(At least 1 message is required)',
  'campaign.chatads.editor.message.type.title': 'Message types',
  'campaign.chatads.editor.text.message.btn': 'Text',
  'campaign.chatads.editor.carousel.message.btn': 'Carousel',
  'campaign.chatads.editor.imagemap.message.btn': 'Imagemap',
  'campaign.chatads.editor.option.message.btn': 'Option',
  'campaign.chatads.editor.delete.modal.confirm.btn': 'Delete',
  'campaign.chatads.editor.delete.modal.cancel.btn': 'Cancel',
  'campaign.chatads.editor.delete.modal.title': 'Are you sure you want to delete?',
  'campaign.chatads.editor.change.topic.modal.confirm.btn': 'Leave topic',
  'campaign.chatads.editor.change.topic.modal.cancel.btn': 'Stay',
  'campaign.chatads.editor.change.topic.modal.title':
    'You didn’t create topic! Are you sure you want to go other topics without saving?',
  'campaign.chatads.editor.modal.choose.message.type.confirm.btn': 'Confirm',
  'campaign.chatads.editor.modal.choose.message.type.cancel.btn': 'Cancel',
  'campaign.chatads.editor.delete.message.modal.title': 'Delete message?',
  'campaign.chatads.editor.delete.message.modal.confirm.btn': 'Confirm',
  'campaign.chatads.editor.delete.message.modal.cancel.btn': 'Cancel',
  'campaign.chatads.editor.modal.alert.topic.already.exist.title':
    'Topic name already exist',
  'campaign.chatads.editor.modal.alert.topic.already.exist.description':
    'Please change your topic name',
  'campaign.chatads.editor.modal.alert.duplicate.triggered.keyword.title':
    'Triggered keyword already exist',
  'campaign.chatads.editor.modal.alert.duplicate.triggered.keyword.description':
    'Please change your triggered keyword',
  'campaign.chatads.editor.modal.alert.empty.message.title':
    'At least 1 message is required',
  'campaign.chatads.editor.modal.alert.empty.message.description': 'Please add message',
  'campaign.chatads.editor.modal.alert.ok.btn': 'OK',
  'campaign.chatads.editor.modal.alert.title': 'Oops! something missing',
  'campaign.chatads.editor.modal.alert.description':
    'Please make sure you finish your edit in template. name or message content',
  'campaign.chatads.editor.content.selected.template.suffix': 'template',
  'campaign.chatads.editor.modal.preview.cancel.btn': 'Cancel',
  'campaign.chatads.editor.modal.preview.title': 'ChatAds Preview',
  'campaign.chatads.editor.modal.preview.list.item1':
    'Scan QR code to preview your ChatAds template in LINE application. Make sure',
  'campaign.chatads.editor.modal.preview.list.item2':
    ' you save the updated template before preview latest version.',
  // campaign chatads editor carousel
  'campaign.chatads.editor.carousel.delete.modal.confirm': 'Confirm',
  'campaign.chatads.editor.carousel.delete.modal.cancel': 'Cancel',
  'campaign.chatads.editor.carousel.item.alert.replace.word.title': 'Add image or title',
  'campaign.chatads.editor.carousel.item.alert.replace.word.description':
    'If you add image or title in carousel, the text limitation will change from 120 to 60 characters. Do you want to continue?',
  'campaign.chatads.editor.choose.template.title': 'Choose ChatAds template!',
  'campaign.chatads.editor.choose.template.description':
    'By click template first before create ChatAds.',
  //richmenu
  'richmenu.title': 'Rich Menu',
  'richmenu.disign.guide.button': 'Design guide',
  'richmenu.new.richmenu.button': 'New rich menu',
  'richmenu.navbar.richmenu.label': 'Rich menus',
  'richmenu.navbar.management.label': 'Managements',
  'richmenu.search.placehoder': 'Search',
  'richmenu.search.button': 'Search',
  'richmenu.list.title': 'All rich menus',
  'richmenu.empty.state.title': 'Create rich menu',
  'richmenu.empty.state.description': 'Let’s create some rich menu',
  'richmenu.no.result.found.title': 'No results found',
  'richmenu.no.result.found.description': 'Try changing the search keywords',
  'richmenu.item.type.label': 'type',
  'richmenu.item.created.date.label': 'created date',
  'richmenu.item.channel.used.label': 'channel used',
  // richmenu editor
  'richmenu.editor.back.to.richmenu.list': 'Back to Rich Menu',
  'richmenu.editor.duplicate.new.btn': 'Duplicate for create new',
  'richmenu.editor.preview.hint.title':
    'Can not edit or delete because Rich Menu is used',
  'richmenu.editor.name.placeholder': 'Untitled template',
  'richmenu.editor.image.guide.button': 'image guide',
  'richmenu.editor.delete.btn': 'Delete',
  'richmenu.editor.cancel.btn': 'Cancel',
  'richmenu.editor.create.btn': 'Create',
  'richmenu.editor.update.btn': 'Update',
  'richmenu.editor.pattern.title': 'Rich menus patterns',
  'richmenu.editor.change.type.modal.title': 'Change size',
  'richmenu.editor.change.type.modal.description.line1':
    'If you want to change size, the rich menus will clear all mapped in previous size.',
  'richmenu.editor.change.type.modal.description.line2': 'Do you want to continue?',
  'richmenu.editor.change.pattern.modal.title': 'Change pattern',
  'richmenu.editor.change.pattern.modal.txt':
    'If you want to change pattern, all action mapped will clear. Do you want to continue?',
  'richmenu.editor.change.modal.confirm.btn': 'Clear and continue',
  'richmenu.editor.change.modal.cancel.btn': 'Cancel',
  'richmenu.editor.create.success': 'Create rich menu successfully',
  'richmenu.editor.duplicate.success': 'Duplicate rich menu successfully',
  'richmenu.editor.update.success': 'Rich menu updated',
  'richmenu.editor.delete.success': 'Delete rich menu successfully',
  'richmenu.editor.delete.modal.confirm.btn': 'Delete',
  'richmenu.editor.delete.modal.cancel.btn': 'Cancel',
  'richmenu.editor.delete.modal.title': 'Delete rich menu',
  'richmenu.editor.delete.modal.description':
    'Are you sure? you want to delete this rich menu.',
  'richmenu.editor.error.max.action.map.title': 'Maximum action area',
  'richmenu.editor.error.max.action.map.description':
    'You can add map actions less than\n{maxRegions} only',
  // richmenu design guide modal
  'richmenu.design.guide.title': 'Design guide',
  'richmenu.design.guide.image.title': 'Rich menu image',
  'richmenu.design.guide.image.description.line1':
    'In order to  display your rich menu, you’ll need to select an image to serve as it’s background.',
  'richmenu.design.guide.image.description.line2':
    'Make sure your image matches the template you select.',
  'richmenu.design.guide.type.templage.title': 'Types and template of rich menus',
  'richmenu.design.guide.type.templage.description.line1':
    'We have 2 types of rich menus, original and compact.',
  'richmenu.design.guide.type.templage.description.line2':
    'You can download templates below to help you match your image to your preferred template.',
  'richmenu.design.guide.download.template.button': 'Download templates',
  // richmenu image guide modal
  'richmenu.image.guide.title': 'Image guide',
  'richmenu.image.guide.original.size.title': 'Original size guide',
  'richmenu.image.guide.compact.size.title': 'Compact size guide',
  'richmenu.image.guide.file.format.field': 'File format :',
  'richmenu.image.guide.image.size.field': 'Image size :',
  'richmenu.image.guide.max.file.size.field': 'Max file size :',
  'richmenu.image.guide.file.format.value': 'JPEG or PNG',
  'richmenu.image.guide.max.file.size.value': '1 MB',
  'richmenu.image.guide.image.size.original.value': '2500×1686 px',
  'richmenu.image.guide.image.size.compact.value': '2500×843 px',
  'richmenu.image.guide.download.template.button': 'Download templates',
  // richmenu image
  'richmenu.image.change.image.btn': 'Change Image',
  'richmenu.image.upload.image.btn': 'Upload Image',
  'richmenu.image.image.placeholder.title': 'Mapper area.',
  'richmenu.image.image.placeholder.description':
    'You can do anything, upload image or choose the patterns you need for create rich menus',
  'richmenu.image.upload.file.too.large.title.line1': 'The file you have selected is',
  'richmenu.image.upload.file.too.large.title.line2': 'too large',
  'richmenu.image.upload.file.too.large.description': 'The maximum file size is 1MB.',
  'richmenu.image.upload.file.wrong.size.title': 'Image size must be {width}×{height}',
  'richmenu.image.upload.file.wrong.dimension.description':
    'You can check and download in',
  'richmenu.image.upload.file.image.guide.button': 'image guide',
  // richmenu editor error
  'richmenu.editor.error.upload.file.txt': 'Upload file failed please try again later',
  'richmenu.editor.error.action.required.description': 'Action area is required',
  // richmenu management
  'richmenu.management.list.all.channel.label': 'All channels',
  'richmenu.management.list.default.richmenu.label': 'Default rich menu',
  'richmenu.management.list.richmenu.in.channel.label': 'Rich menu in channel',
  'richmenu.management.list.richmenu.active.label': 'Active',
  'richmenu.management.list.richmenu.upcoming.label': 'Upcoming',
  'richmenu.management.list.richmenu.mouse.hover.label': 'Setup rich menu',

  //richmenu channel list
  'richmenu.channel.list.description': 'Link rich menu for shown in your channel',
  'richmenu.channel.list.active.label': 'Active',
  'richmenu.channel.list.upcoming.label': 'Label',
  'richmenu.channel.list.add.richmenu.btn': 'Link Rich Menu',
  'richmenu.channel.list.item.detail.title': 'Details',
  'richmenu.channel.list.item.name.label': 'Rich menu',
  'richmenu.channel.list.item.menu.bar.label.label': 'Menu bar label',
  'richmenu.channel.list.item.menu.behavior.label': 'Menus behavior',
  'richmenu.channel.list.item.priority.label': 'Priorities',
  'richmenu.channel.list.item.target.audience.label': 'Target audience',
  'richmenu.channel.list.item.date.to.add.label': 'Date to add',
  'richmenu.channel.list.no.active.richmenu.description': 'No rich menu active',
  'richmenu.channel.list.no.upcoming.richmenu.description': 'No rich menu upcoming',
  'richmenu.channel.list.all.user.value': 'All users',
  'richmenu.channel.list.all.user.description': '(Default rich menu)',
  'richmenu.channel.list.auto.display.value': 'Auto-displayed',
  'richmenu.channel.list.collapes.value': 'Collapsed',
  'richmenu.channel.list.delete.title': 'Delete rich menu',
  'richmenu.channel.list.delete.active.description':
    'Your rich menu in channel will not show to your users.',
  'richmenu.channel.list.delete.success': 'Rich menu deleted successfully',
  'richmenu.channel.list.delete.upcoming.description':
    'Your scheduled rich menu will delete.',
  // richmenu add to channel
  'richmenu.add.title': 'Link Rich Menu',
  'richmenu.add.cancel.btn': 'Cancel',
  'richmenu.add.add.btn': 'Link',
  'richmenu.add.update.btn': 'Update',
  'richmenu.add.select.richmenu.placeholder': 'Select rich menu',
  'richmenu.add.select.richmenu.btn': 'Select rich menu',
  'richmenu.add.menu.bar.label.title': 'Menu bar label',
  'richmenu.add.menu.bar.label.placeholder': 'Menu',
  'richmenu.add.menu.bar.label.description': 'Default is “Menu”',
  'richmenu.add.menu.behavior.title': 'Menu behavior',
  'richmenu.add.menu.behavior.auto.display': 'Auto-Display',
  'richmenu.add.menu.behavior.collapsed': 'Collapsed',
  'richmenu.add.advance.setting.title': 'Advance setting',
  'richmenu.add.target.audience.title': 'Target audience',
  'richmenu.add.target.all.user': 'All users',
  'richmenu.add.target.all.user.description': '(Default rich menu)',
  'richmenu.add.target.segment': 'Segment',
  'richmenu.add.target.segment.select.placeholder': 'Select segment',
  'richmenu.add.date.add.richmenu.title': 'Date to link rich menu',
  'richmenu.add.date.add.richmenu.now': 'Now',
  'richmenu.add.date.add.richmenu.setup.date.time': 'Setup date and time',
  'richmenu.add.richmenu.priority.title': 'Rich menu priorities',
  'richmenu.add.richmenu.priority.placeholder': '01',
  'richmenu.add.richmenu.priority.description':
    'This value determines the importance of rich menu to be served when you in more than 1 segments.',
  'richmenu.add.select.richmenu.modal.title': 'Select rich menu',
  'richmenu.add.select.richmenu.search.placeholder': 'Search',
  'richmenu.add.select.richmenu.search.btn': 'Search',
  'richmenu.add.select.richmenu.cancel.btn': 'Cancel',
  'richmenu.add.select.richmenu.confirm.btn': 'Confirm',
  'richmenu.add.adding.richmenu.success': 'Add rich menu successfully',
  'richmenu.add.updating.richmenu.success':
    'Rich menu updated successfully. This page will be refresh in 3 seconds.',
  'richmenu.add.select.richmenu.no.available': 'No available rich menu',
  'richmenu.add.error.menu.bar.label.wrong.format.title': 'Wrong format menu bar label',
  'richmenu.add.error.menu.bar.label.wrong.format.description':
    'Please make sure you follow menu bar label name conditions alphabets, 0-9, spacebar',
  'richmenu.add.error.time.past.title': 'Check your time',
  'richmenu.add.error.time.past.description': 'You cannot choose time in the past',

  // audience
  'audience.list.title': 'Audience',
  'audience.all.channel.label': 'All channels',
  'audience.list.search.placeholder': 'Search by user ID, display name',
  'audience.list.empty.title': 'No audience now',
  'audience.list.empty.description': 'New audience will show up here',
  'audience.list.search.empty.title': 'No results found',
  'audience.list.search.empty.description': 'Try changing the search keywords',
  'audience.list.error.title': ERROR_TITLE_DEAULT_MESSAGE,
  'audience.list.error.description': ERROR_DESCRIPTION_DEAULT_MESSAGE,
  'audience.list.search.btn': 'Search',
  'audience.profile.breadcrumb.audience.list': 'Audience',
  'audience.profile.breadcrumb.audience.profile': 'Audience profile',
  'audience.list.table.view.profile.btn': 'View profile',
  'audience.profile.tab.title.user.tag': 'User tag',
  'audience.profile.empty.state.title': 'No tag now',
  'audience.profile.empty.state.description': 'New tag will show up here.',
  'audience.profile.tab.title.ticket.history': 'Ticket history',
  'audience.profile.tab.title.custom.field': 'Custom field',
  'audience.profile.tab.title.custom.tag': 'Custom tag',
  'audience.profile.tab.custom.field.title': 'Custom field',
  'audience.profile.tab.custom.field.description':
    'Lorem ipsum dolor sit amet consectetur. Massa porta a velit eu at tellus mauris aliquam.',
  'audience.profile.tab.custom.field.empty.state.title': 'No custom field now',
  'audience.profile.tab.custom.field.empty.state.description':
    'New custom field will show up here.',
  'audience.profile.tab.custom.field.text.format': 'Text format',
  'audience.profile.tab.custom.field.image.format': 'Image format',
  'audience.profile.tab.custom.field.action.format': 'Action format',

  'audience.profile.tab.user.tag.intent.title': 'Latest intent',
  'audience.profile.tab.user.tag.intent.description':
    'Lorem ipsum dolor sit amet consectetur. Massa porta a velit eu at tellus mauris aliquam.',

  'audience.profile.tab.user.tag.entity.title': 'Latest entity',
  'audience.profile.tab.user.tag.entity.description':
    'Lorem ipsum dolor sit amet consectetur. Massa porta a velit eu at tellus mauris aliquam.',

  'audience.profile.tab.custom.tag.title': 'Custom tags',
  'audience.profile.tab.custom.tag.description':
    'Lorem ipsum dolor sit amet consectetur. Massa porta a velit eu at tellus mauris aliquam.',
  'audience.profile.tab.custom.tag.search.help.text':
    '(a-z, A-Z, ก-ฮ,  0-9, - , _, /, Max 100 characters, Max 100 tag)',
  'audience.profile.tab.custom.tag.empty.tags.list.title': 'No custom tag now',
  'audience.profile.tab.custom.tag.empty.tags.list.description':
    'Let’s add new custom tag.',
  'audience.profile.tab.custom.tag.search.placeholder': 'Type something to search',
  'audience.profile.tab.custom.tag.add.button': 'Add new',
  'audience.profile.tab.custom.tag.modal.alert.duplicated.tag.title':
    'This custom tag already exists',
  'audience.profile.tab.custom.tag.modal.alert.duplicated.tag.description':
    'Please change your tag name again.',
  'audience.profile.tab.custom.tag.modal.alert.ok.btn': 'OK',
  'audience.profile.tab.custom.tag.modal.delete.label': 'Delete custom tag',
  'audience.profile.tab.custom.tag.modal.delete.description':
    'Are you sure you want to delete this custom tag?',
  'audience.profile.tab.custom.tag.modal.confirm.delete.btn': 'Delete',
  'audience.profile.tab.custom.tag.modal.cancel.delete.btn': 'Cancel',
  'audience.list.dropdown.filter.placeholder': 'All channels selected',
  'audience.list.dropdown.filter.select.all': 'All channels selected',
  'audience.list.dropdown.filter.multiple.select': 'channels selected',

  // flow
  'flow.list.title': 'Chatlogic',
  'flow.list.description': 'Manage your chat logic and link logic',
  'flow.list.all.logic': 'All logic',
  'flow.export.btn': 'Export',
  'flow.list.empty.title': 'No flow list now',
  'flow.list.empty.description': 'New flow list will show up here',
  'flow.export.modal.title': 'Export report',
  'flow.export.modal.report.type': 'Type of reports',
  'flow.export.modal.report.message': 'chatbot: message report',
  'flow.export.modal.report.nlp': 'chatbot: NLP performance',
  'flow.export.modal.report.timeframe': 'Timeframe',
  'flow.export.modal.report.time.to': 'to',

  // report
  'report.download.title': 'File downloading, please don’t close this window.',
  'report.downloading': ' Downloading...',
  'report.404.title1': '404.',
  'report.404.title2': 'That’s an error',
  'report.404.description': 'The report was not found.',
  'report.finish.download.text': 'Finished, your file ready to download',
  'report.download.file.text': 'Download file',

  // bot setting
  'bot.setting.update.success': 'Update successfully',
  'bot.setting.title': 'Setting',
  'bot.setting.view.mode.label': 'View only',
  'bot.setting.general.description':
    "Set minimum level of confidence that bot will treat as successful and fallback action when it's failed",
  'bot.setting.change.tab.without.save.title':
    'Are you sure you want to leave this tab without saving?',
  'bot.setting.change.tab.without.save.description':
    'This page that you made will not be saved.',
  'bot.setting.general.title': 'General',
  'bot.setting.general.confidence.label': 'Confidence Threshold',
  'bot.setting.general.confidence.help': 'Value is 0.01 - 1.00',
  'bot.setting.general.fail.action.label': 'Fail action',
  'bot.setting.general.conversation.flow.engine.selected.label': 'Conversation flow',
  'bot.setting.general.conversation.flow.selected.label': 'Start action',
  'bot.setting.general.line.typing.animation.label': 'LINE typing animation',
  'bot.setting.line.typing.animation.description':
    'After your LINE Official Account receives a message from a user, the response may takes some time due to message preparation or reservation processing. ',
  'bot.setting.line.typing.animation.label': 'Duration',
  'bot.setting.line.typing.animation.unit': 'Seconds',
  'bot.setting.line.typing.animation.hint':
    'Supported LINE channel only, minimum 5 seconds and maximum 60 seconds',
  'bot.setting.location.conversation.flow.engine.selected.label': 'Conversation flow',
  'bot.setting.location.conversation.flow.selected.label': 'Start action',
  'bot.setting.conversation.flow.placeholder': 'Select option',
  'bot.setting.conversation.flow.no.result': 'No result',
  'bot.setting.required.field': 'Please fill information',
  'bot.setting.gobal.delay.title': 'Global delay',
  'bot.setting.location.action.label': 'Location action',

  'bot.setting.webhook.title': 'Webhook Configuration',
  'bot.setting.webhook.description':
    'The URL of an endpoint that can process webhook events sent by the AmityBots Platform',
  'bot.setting.cancel.button.title': 'Reset',
  'bot.setting.update.button.title': 'Update',

  'bot.setting.webhook.url.validate.error': 'Please enter a valid URL.',
  'bot.setting.webhook.enrich.label': 'Enrich',
  'bot.setting.webhook.enrich.url.label': 'Enter your custom url.',
  'bot.setting.webhook.enrich.placeholder.label': 'Enter your custom url.',
  'bot.setting.webhook.template.label': 'Template',
  'bot.setting.webhook.template.url.label': 'Enter your custom url.',
  'bot.setting.webhook.template.placeholder.label': 'Enter your custom url.',
  'bot.setting.webhook.outgoing.label': 'Outgoing',
  'bot.setting.webhook.outgoing.url.label': 'Enter your custom url.',
  'bot.setting.webhook.outgoing.placeholder.label': 'Enter your custom url.',
  'bot.setting.webhook.classifier.label': 'Classifier',
  'bot.setting.webhook.classifier.placeholder.label': 'Enter your custom url.',
  'bot.setting.webhook.custom.url.label': 'Enter your custom url.',
  // bot variable
  'bot.variable.content.title': 'Variable',
  // bot variable editor
  'bot.variable.editor.update.mode.title': 'Edit variable',
  'bot.variable.editor.update.mode.cancel.btn': 'cancel',
  'bot.variable.editor.update.mode.update.btn': 'update',
  'bot.variable.editor.create.mode.title': 'Create variable',
  'bot.variable.editor.create.mode.create.btn': 'Create',
  'bot.variable.editor.name.label': 'Name',
  'bot.variable.editor.name.label.placeholder': 'Create name',
  'bot.variable.editor.name.helper': BOT_VARIABLE_VALIDATE_MESSAGE.NAME_REGEX,
  'bot.variable.editor.type.label': 'Type',
  'bot.variable.editor.type.placeholder': 'Select option',
  'bot.variable.editor.value.label': 'Value',
  'bot.variable.editor.value.placeholder': 'Enter value',
  'bot.variable.editor.value.helper': BOT_VARIABLE_VALIDATE_MESSAGE.VALUE_REGEX,
  'bot.variable.editor.scope.label': 'Scope',
  'bot.variable.editor.scope.placeholder': 'Select option',

  'bot.variable.editor.delete.modal.confirm.btn': 'Delete',
  'bot.variable.editor.delete.modal.cancel.btn': 'Cancel',
  'bot.variable.editor.delete.modal.title': 'Delete variable',
  'bot.variable.editor.delete.modal.description':
    'Are you sure you want to delete this variable?',

  'network.setting.provider.socket.update': 'There is an update on network setting',
  // password policy
  'bot.setting.password.policy.title': 'Password policy',
  'bot.setting.password.policy.super.admin.password.label': 'Super Admin Password',
  'bot.setting.password.policy.password.change.label': 'Password Change',
  'bot.setting.password.policy.auto.disable.user.label': 'Auto-Disable User',
  'bot.setting.password.policy.account.lock.user.label': 'Account Lock User',
  'bot.setting.password.policy.duplicate.password.prevention.label':
    'Duplicate Password Prevention',
  'bot.setting.password.policy.force.change.password.label': 'Force Change Password',
  'bot.setting.password.policy.force.change.password.description':
    'Specify the time period for required to change password.',
  'bot.setting.password.policy.force.change.password.sup.label': 'Force change {role} in',
  'bot.setting.password.policy.force.change.password.sup.unit': 'days',
  'bot.setting.password.policy.force.change.password.normal.label':
    'Force change {role} in',
  'bot.setting.password.policy.force.change.password.normal.unit': 'days',
  'bot.setting.password.policy.two.factor.authentication.label':
    'Two-Factor Authentication',

  'bot.setting.password.policy.superadmin.description':
    'Use specific password conditions for Super admin role.',
  'bot.setting.password.policy.superadmin.min.label': 'Minimum',
  'bot.setting.password.policy.superadmin.max.label': 'Maximum',
  'bot.setting.password.policy.superadmin.unit': 'characters.',
  'bot.setting.password.policy.superadmin.additional.label':
    'Requires a combination of alphanumeric characters, uppercase and lowercase letters, and special characters.',

  'bot.setting.password.policy.change.description':
    'The maximum change passwords within a 24-hours.',
  'bot.setting.password.policy.change.max.label': 'Maximum',
  'bot.setting.password.policy.change.unit': 'time.',

  'bot.setting.password.policy.duplicate.password.prevention.description':
    'Set the number of restricts users from using the same password within a certain number of previous passwords.',
  'bot.setting.password.policy.duplicate.password.prevention.last.label': 'Their last',
  'bot.setting.password.policy.duplicate.password.prevention.unit': 'passwords.',

  // auth policy
  'bot.setting.auth.policy.account.lock.description':
    'Temporarily locks a user for login after a specified number of consecutive failed login attempts.',
  'bot.setting.auth.policy.account.lock.after.label': 'After',
  'bot.setting.auth.policy.account.lock.unit':
    'consecutive failed login attempts in 5 minutes.',
  'bot.setting.auth.policy.account.lock.locked.label': 'Locked',
  'bot.setting.auth.policy.account.lock.locked.unit': 'minutes.',
  'bot.setting.auth.policy.auto.disabled.description':
    'Automatically disable user accounts when the last active time exceeds a specified threshold.',
  'bot.setting.auth.policy.last.active.label': 'Last active',
  'bot.setting.auth.policy.last.active.unit': 'days.',

  // chat insight
  'chat.insight.title': 'Chat Insight',
  'chat.insight.mention.results.title': 'Mention Results',
  'chat.insight.sentiment.title': 'Sentiment',
  'chat.insight.overall.sentiment.title': 'Total Sentiment',

  // sso
  'sso.page.title': 'Sign in with SSO',
  'sso.page.description1':
    "Single sign-on allows you to log in to Amity Bot Platform using your organization's identity provider.",
  'sso.page.description2':
    'If you are unsure whether your organization is using reach out to your administrator.',
  'sso.page.organizationDomain': 'Organization domain',
  'sso.page.tooltip.rule1': 'Max 32 characters',
  'sso.page.tooltip.rule2': 'Can be alphabet',
  'sso.page.tooltip.rule3': 'Can be number',
  'sso.page.tooltip.rule4': 'No special character',
  'sso.page.tooltip.rule5': 'Only dash (-) allowed',
  'sso.page.error.maxLength': 'Max 32 characters',
  'sso.page.error.invalid': 'Invalid domain, accept only alphabet, number and dash (-)',
  'sso.page.error.notFound': 'Organization domain does not exist',
  'sso.page.placeholder': 'Enter your organization domain',
  'sso.page.signInButton': 'Sign in with SSO',
  'sso.page.backButton': 'Back',
  'sso.page.organizationDomain.placeholder': 'Enter your organization domain',
};
